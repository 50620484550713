import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Title from 'components/title';
import { Paragraph } from './findus.css';
import Basic from 'components/group/basic';
// import { useHoursQuery } from '../../hooks/useHoursQuery';

const FindUs = () => {
  const data = useStaticQuery(graphql`
    {
      hours: allGoogleHoursSheet {
        edges {
          node {
            id
            message
          }
        }
      }
    }
  `);
  return (
    <div id="findus" style={{ textAlign: 'center', marginBottom: '10rem' }}>
      <Title as="h2" size="xlarge">
        Find Us
      </Title>
      <Paragraph>
        26 S. Clinton Street
        <br />
        Iowa City, Iowa
        <br />
        <a
          target="_blank"
          href="https://wego.here.com/directions/mix//Cortado-Coffee-Cafe,-26-S.-Clinton-Street,-Iowa-City,-Iowa:e-eyJuYW1lIjoiQ29ydGFkbyBDb2ZmZWUgJiBDYWZlIiwiYWRkcmVzcyI6IjI2IFMuIENsaW50b24gU3RyZWV0LCBJb3dhIENpdHksIElvd2EiLCJsYXRpdHVkZSI6NDEuNjYwNDMsImxvbmdpdHVkZSI6LTkxLjUzNDI0LCJwcm92aWRlck5hbWUiOiJmYWNlYm9vayIsInByb3ZpZGVySWQiOjYzMjU4ODkyMDI2MTMyMn0=?map=41.66043,-91.53424,15,normal&amp;fb_locale=en_US"
          rel="noreferrer noopener"
        >
          Get Directions
        </a>
        <br />
        <br />
        Phone: <a href="tel:3195192340">319.519.2340</a>
        <br />
        <br />
        To find our most up to date hours, please see our details on Google.
        <br />
        <a href="https://www.google.com/search?q=cortado+iowa+city" target="_blank" rel="external">View the Latest Open Hours</a>
      </Paragraph>
      {/*
      <Paragraph>
        {data.hours.edges.map((d) => (
          <Basic key={d.node.id} message={d.node.message} />
        ))}
      </Paragraph>
      */}
    </div>
  );
};

export default FindUs;
