import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Items from './items';
// import { Table } from './table.css';

const BakeryBreakfast = () => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const data = useStaticQuery(graphql`
    {
      bakeryBreakfast: allGoogleMenuSheet(
        filter: { category: { eq: "bakery_breakfast" } }
      ) {
        edges {
          node {
            category
            description
            exception
            price01
            subtitle
            title
            id
          }
        }
      }
    }
  `);
  return (
    <>
      {data.bakeryBreakfast.edges.map((d) => (
        <Items
          key={d.node.id}
          title={d.node.title}
          subtitle={d.node.subtitle}
          description={d.node.description}
          price01={formatter.format(d.node.price01)}
          exception={d.node.exception.toString()}
        />
      ))}
    </>
  );
};

export default BakeryBreakfast;
