import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Table = styled.table`
  width: 100%;
  min-width: 100%;
  width: auto;
  flex: 1;
  display: grid;
  border-collapse: collapse;
  grid-template-columns: minmax(100px, 9fr) minmax(50px, 3fr) minmax(50px, 3fr) minmax(
      50px,
      3fr
    );

  ${MEDIA.TABLET`
    width: 100%;
  `};

  thead,
  tbody,
  tr {
    display: contents;
  }

  th,
  td {
    padding: 1rem;
    overflow: hidden;
    white-space: nowrap;
  }

  thead th {
    text-align: right;
  }

  th {
    position: sticky;
    top: 0;
    background: #822329;
    text-align: left;
    font-weight: normal;
    font-size: 1.1rem;
    color: white;
    // position: relative;
  }

  th:last-child {
    border: 0;
  }

  tbody td:first-child {
    text-align: left;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 0;
  }

  tbody td {
    text-align: right;
  }

  td {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
`;
