import React from 'react';
import PropTypes from 'prop-types';

const TableRowOne = (props) => (
  <tr>
    <td>{props.title}</td>
    <td>{props.price01}</td>
    <td>{props.price02}</td>
    <td>{props.price03}</td>
  </tr>
);

TableRowOne.propTypes = {
  title: PropTypes.string,
  category: PropTypes.string,
  price01: PropTypes.string,
  price02: PropTypes.string,
  price03: PropTypes.string,
};

export default TableRowOne;
