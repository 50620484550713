import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { Container } from './totop.css';

const ToTop = () => (
  <Container>
    <AnchorLink to="/#___gatsby" title="Return to the top of the content page">
      <span>Scroll to Top</span>
    </AnchorLink>
  </Container>
);

export default ToTop;
