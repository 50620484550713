import React from 'react';
import PropTypes from 'prop-types';

const Single = (props) => (
  <tr>
    <td>{props.title}</td>
    <td>{props.price01}</td>
    <td></td>
    <td></td>
  </tr>
);

Single.propTypes = {
  title: PropTypes.string,
  category: PropTypes.string,
  price01: PropTypes.string,
};

export default Single;
