import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  display: block;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0rem;
  padding: 0;
  margin: 2rem 0;

  ${MEDIA.MIN_TABLET`
    display: grid;
  `};

  ${MEDIA.MIN_LAPTOP`
    grid-template-columns: repeat(3, 1fr);
  `};

  figcaption {
    display: none;
  }
`;
