import styled from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';

export const GroupItem = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 6fr) minmax(40px, 2fr);
  flex: 1;
  align-items: top;
  justify-content: space-between;
  margin-bottom: 1.25rem;

  ${MEDIA.MIN_TABLET`
    margin-bottom: 1.5rem;
  `};
`;

export const GroupTitle = styled.div`
  h3 {
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }
`;
