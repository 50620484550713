import React from 'react';
import PropTypes from 'prop-types';
import { GroupItem, GroupTitle } from './items.css';

const Items = (props) => (
  <GroupItem>
    <GroupTitle>
      <h3>{props.title}</h3>
      <div className="group__subtitle">{props.subtitle}</div>
      <div className="group__description">{props.description}</div>
    </GroupTitle>
    <div className="group__price">{props.price01}</div>
  </GroupItem>
);

Items.propTypes = {
  props: PropTypes.any,
  id: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  category: PropTypes.string,
  description: PropTypes.string,
  price01: PropTypes.string,
  exception: PropTypes.string,
};

export default Items;
