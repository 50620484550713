import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Single from 'components/group/single';
import { Table } from './table.css';

const Shot = () => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const data = useStaticQuery(graphql`
    {
      Shots: allGoogleMenuSheet(filter: { category: { eq: "shot" } }) {
        edges {
          node {
            category
            price01
            title
            id
          }
        }
      }
    }
  `);
  return (
    <Table>
      <tbody>
        {data.Shots.edges.map((d) => (
          <Single
            key={d.node.id}
            title={d.node.title}
            price01={formatter.format(d.node.price01)}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default Shot;
