import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import HummusItems from './hummusitems';
// import { Table } from './table.css';

export const NowAvailableTitle = styled.div`
  font-size: 3.2rem;
  color: #000000;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  display: table;
  white-space: nowrap;
  overflow: hidden;

  ${MEDIA.TABLET`
    margin-top: 5rem;
  `};

  &:before,
  &:after {
    border-top: 1px solid #000000;
    content: '';
    display: table-cell;
    position: relative;
    top: 4.5rem;
    width: 48.5%;
  }

  &:before {
    right: 1.5%;
  }
  &:after {
    left: 1.5%;
  }

  span {
    font-size: 2.6rem;
    font-weight: 500;
  }
`;

export const NowAvailableWrapper = styled.div`
  padding: 2rem;
  text-align: center;
  margin: 1rem auto;
  background-color: #339bdb;
  color: #000000;
  border-radius: 8px;
  width: 80%;

  ${MEDIA.TABLET`
    margin-bottom: 5rem;
  `};
`;

export const NowAvailableSubTitle = styled.div`
  font-size: 3.2rem;
  font-weight: 700;
  margin-bottom: 1rem;
`;

export const NowAvailableText = styled.div`
  font-size: 2.4rem;
  color: #ffffff;
  display: grid;
  grid-template-columns: 1fr 1fr;

  span {
    font-weight: 700;
  }
`;

const HummusPromo = () => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const data = useStaticQuery(graphql`
    {
      hummusEight: allGoogleMenuSheet(
        filter: {
          category: { eq: "hummus" }
          title: { eq: "Cortado Hummus" }
          subtitle: { eq: "8oz" }
        }
      ) {
        edges {
          node {
            category
            description
            exception
            price01
            subtitle
            title
            id
          }
        }
      }
      hummusSixteen: allGoogleMenuSheet(
        filter: {
          category: { eq: "hummus" }
          title: { eq: "Cortado Hummus" }
          subtitle: { eq: "16oz" }
        }
      ) {
        edges {
          node {
            category
            description
            exception
            price01
            subtitle
            title
            id
          }
        }
      }
    }
  `);
  return (
    <>
      <NowAvailableTitle>
        <span>Now Available to</span> <br />
        Purchase
      </NowAvailableTitle>
      <NowAvailableWrapper>
        <NowAvailableSubTitle>CORTADO Hummus</NowAvailableSubTitle>
        <NowAvailableText>
          <div>
            <span>
              {data.hummusEight.edges.map((d) => (
                <HummusItems
                  key={d.node.id}
                  title={d.node.title}
                  subtitle={d.node.subtitle}
                  description={d.node.description}
                  price01={formatter.format(d.node.price01)}
                  exception={d.node.exception.toString()}
                />
              ))}
            </span>{' '}
            <br />
            8oz.
          </div>
          <div>
            <span>
              {data.hummusSixteen.edges.map((d) => (
                <HummusItems
                  key={d.node.id}
                  title={d.node.title}
                  subtitle={d.node.subtitle}
                  description={d.node.description}
                  price01={formatter.format(d.node.price01)}
                  exception={d.node.exception.toString()}
                />
              ))}
            </span>
            <br />
            16oz.
          </div>
        </NowAvailableText>
      </NowAvailableWrapper>
    </>
  );
};

export default HummusPromo;
