import React from 'react';
import PropTypes, { nominalTypeHack } from 'prop-types';
import Layout from 'components/layout';
import Title from 'components/title';
import Gallery from 'components/gallery';
// import IOExample from 'components/io-example';
// import Modal from 'containers/modal';
import ToTop from 'components/totop';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
// import AllItems from 'components/group/allitems';
import Cortado from 'components/group/cortado';
import Shot from 'components/group/shot';
import HummusPromo from 'components/group/hummuspromo';
import CoffeeBarSingleDoubleTriple from 'components/group/coffeebarsingledoubletriple';
import CoffeeBarEight from 'components/group/coffeebareight';
import CoffeeBarTwelve from 'components/group/coffeebartwelve';
import CoffeeBarSixteen from 'components/group/coffeebarsixteen';
import Salads from 'components/group/salads';
import BakeryBreakfast from 'components/group/bakerybreakfast';
import Sandwiches from 'components/group/sandwiches';
import Hummus from 'components/group/hummus';
import FindUs from 'components/findus';

// import Img from 'gatsby-image';
// import Menu01 from 'images/cortado_2023_01.jpeg';
// import Menu02 from 'images/cortado_2023_02.jpeg';

export const Article = styled.article`
  display: block;
  grid-gap: 0.5rem 5rem;
  grid-template-columns: 1fr 1fr;

  ${MEDIA.MIN_LAPTOP`
    display: grid;
  `};
`;

export const ColOne = styled.div`
  grid-column: 1 / 2;
  display: block;
  grid-gap: 3rem 1rem;
  grid-template-columns: 4rem 1fr;

  ${MEDIA.MIN_TABLET`
    display: grid;
  `};

  h2 {
    grid-column: 1 / 2;
    text-align: center;
    font-size: 3rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 3rem;
    margin-bottom: 1.5rem;

    ${MEDIA.MIN_TABLET`
      text-align: right;
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      margin-top: 0;
      margin-bottom: 0;
    `};
  }
`;

export const ColTwo = styled.div`
  grid-column: 2 / 2;
  display: block;
  grid-gap: 3rem 1rem;
  grid-template-columns: 4rem 1fr;

  ${MEDIA.MIN_TABLET`
    display: grid;
  `};

  h2 {
    grid-column: 1 / 2;
    text-align: center;
    font-size: 3rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 3rem;
    margin-bottom: 1.5rem;

    ${MEDIA.MIN_TABLET`
      text-align: right;
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      margin-top: 0;
      margin-bottom: 0;
    `};
  }
`;

export const Section = styled.div`
  grid-column: 2 / 2;
`;

export const Group = styled.div`
  margin: 0 0 1rem 0;
`;

export const Notice = styled.div`
  margin: 1rem auto;
  font-size: 1.4rem;
  font-style: italic;
  text-align: center;
  display: none;

  ${MEDIA.MIN_TABLET`
    display: none;
  `};
`;

export const FooterImage = styled.div`
  background-image: url('https://s3.amazonaws.com/www.cortadoic.com/images/banners/1200/Cortado_Two-201.jpg');
  background-size: cover;
  background-position: top center;
  min-height: 700px;
  margin: -2rem -1rem;

  ${MEDIA.MIN_TABLET`
    margin: -2rem -4rem;
  `};
`;

export const Menu01 = styled.div`
  background-image: url('https://assets.trafficincool.com/images/cortado/cortado_2023_01.jpeg');
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  min-height: 600px;
  margin: 0 0 0 0;
  display: none;

  ${MEDIA.MIN_TABLET`
    margin: 0 0 0 0;
    display: block;
  `};
`;

export const Menu02 = styled.div`
  background-image: url('https://assets.trafficincool.com/images/cortado/cortado_2023_02.jpeg');
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  min-height: 600px;
  display: none;

  ${MEDIA.MIN_TABLET`
    margin: 0 0 0 0;
    display: block;
  `};
`;

export const MainTop = styled.div`
  background-image: url(https://s3.amazonaws.com/www.cortadoic.com/images/banners/1200/Cortado-144R.jpg);
  transform: translate3d(0, 0, 0);
  background-position: 100%;
  background-size: cover;
  position: relative;
  min-height: 250px;
  height: 50vh;
  margin: -2rem -1rem 2rem -1rem;

  ${MEDIA.MIN_TABLET`
    margin: -2rem -4rem 2rem -4rem;
  `};
`;

export const OrderBtn = styled.span`
  display: block;
  text-align: center;
  background-color: rgb(51, 155, 219);
  color: #ffffff;
  width: 100%;
  max-width: 320px;
  padding: 2.2rem 0;
  margin: 1rem auto;
  border-radius: 8px;
  font-size: 2.2rem;
  font-weight: 500;
  text-transform: uppercase;

  &:hover {
    background-color: #2b8cc8;
    filter: drop-shadow(0 0 0.25rem #2b8cc8);
  }
`;

// export const DoorDashImage = styled.div`
//   position: relative;
//   width: 289px;
//   height: 59px;
//   margin: 0 auto 1rem auto;
//   background-image: url(https://cdn.doordash.com/media/button/289x59_red.png);
//   color: transparent;
// `;

export const Paragraph = styled.p`
  width: 80%;
  margin: 2rem auto 0 auto;
  line-height: 1.4;
  font-size: 2rem;

  ${MEDIA.MIN_TABLET`
    width: 60%;
  `};
`;

const Index = ({ data }) => (
  <Layout>
    <MainTop />
    <div id="main-content">
      {/* <Title as="h2" size="large">
        {data.homeJson.content.childMarkdownRemark.rawMarkdownBody}
      </Title> */}

      <div
        id="order"
        style={{
          marginTop: '6vh',
          marginBottom: '10vh',
          textAlign: 'center',
        }}
      >
        <Title as="h2" size="xlarge">
          Order Online
        </Title>
        <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
          <a
            href="https://cortadoic.square.site"
            target="_blank"
            alt="Order Pickup or Delivery with Cortado"
            style={{ textDecoration: 'none' }}
            rel="noopener noreferrer"
          >
            <OrderBtn>Order Pickup or Delivery</OrderBtn>
          </a>
          {/* <a
            href="https://www.doordash.com/store/415728/?utm_source=partner-link&amp;utm_medium=website&amp;utm_campaign=415728&amp;utm_content=red-medium"
            target="_blank"
            alt="Order Food Delivery with DoorDash"
            title="Order Food Delivery with DoorDash"
            style={{ textDecoration: 'none' }}
            rel="noopener noreferrer"
          >
            <DoorDashImage>Order Food Delivery with DoorDash</DoorDashImage>
          </a>
          <a
            href="https://www.grubhub.com/restaurant/cortado-coffee--cafe-26-s-clinton-st-iowa-city/669461?classicAffiliateId=%2Fr%2Fw%2F58392%2F&amp;utm_source=content-cms.grubhub.com&amp;utm_medium=OOL&amp;utm_campaign=order%20online&amp;utm_content=669461"
            title="Order with GrubHub"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={'/btn--grubhub.png'}
              alt="Order Online with Grubhub"
              style={{ marginBottom: '1rem' }}
            />
          </a> */}
        </div>
      </div>

      <div id="menu" />

      <Menu01 />
      <Menu02 />

      <div style={{ display: 'none' }}>
        <Article>
          <ColOne>
            <h2 style={{ color: '#822329' }}>Coffee Bar</h2>
            <Section>
              <Group>
                <Cortado />
              </Group>
              <Group>
                <CoffeeBarSingleDoubleTriple />
              </Group>
              <Group>
                <CoffeeBarEight />
              </Group>
              <Group>
                <CoffeeBarTwelve />
              </Group>
              <Group>
                <CoffeeBarSixteen />
              </Group>
              <Group>
                <Shot />
              </Group>
            </Section>
            <h2 style={{ color: '#507f2f' }}>Salads</h2>
            <Section>
              <Group>
                <Salads />
              </Group>
            </Section>
            <Section>
              <Group>
                <HummusPromo />
              </Group>
            </Section>
          </ColOne>
          <ColTwo>
            <h2 style={{ color: '#ee701b' }}>Bakery &amp; Breakfast</h2>
            <Section>
              <Group>
                <BakeryBreakfast />
              </Group>
            </Section>
            <h2 style={{ color: '#9c5fa2' }}>Sandwiches</h2>
            <Section>
              <Group>
                <Sandwiches />
              </Group>
            </Section>
            <h2 style={{ color: '#339bdb' }}>Hummus</h2>
            <Section>
              <Group>
                <Hummus />
              </Group>
            </Section>
          </ColTwo>
        </Article>
      </div>

      <Notice>
        *Egg may be cooked to order, consuming raw or undercooked meats,
        poultry, seafood, shellfish, or eggs may increase your risk of
        food-borne illness.
      </Notice>

      <div
        id="photo"
        style={{
          marginTop: '10rem',
          marginBottom: '10rem',
          textAlign: 'center',
        }}
      >
        <Title as="h2" size="xlarge">
          Photos
        </Title>
        <Gallery id="photos" items={data.homeJson.gallery} />
      </div>

      <div id="about" style={{ marginBottom: '10rem', textAlign: 'center' }}>
        <Title as="h2" size="xlarge">
          Our Mission
        </Title>
        <Paragraph>
          We are a metropolitan-inspired, fast-paced, European style espresso
          bar specializing in properly prepared, high quality espresso drinks
          and drip coffee. We offer an assortment of freshly baked pastries and
          unexpected Mediterranean-inspired, nutritious sandwiches, salads, and
          soups.
        </Paragraph>
      </div>

      <FindUs />

      <FooterImage />
      {/* <IOExample /> */}
    </div>
    <ToTop />
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
