import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  right: 3%;
  bottom: 5%;

  a {
    padding: 1rem;
    background: #507f2f;
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1rem;

    &:hover {
      background: #426628 radial-gradient(circle, transparent 1%, #426628 1%)
        center/15000%;
    }
  }
`;
