import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import TableRowOne from 'components/group/tablerowone';
import { Table } from './table.css';

const CoffeeBarTwelve = () => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const data = useStaticQuery(graphql`
    {
      CoffeeBarTwelve: allGoogleMenuSheet(
        filter: { category: { eq: "coffee_bar-12_16_20" } }
      ) {
        edges {
          node {
            category
            price01
            price02
            price03
            title
            id
          }
        }
      }
    }
  `);
  return (
    <Table>
      <thead>
        <tr>
          <th></th>
          <th>12oz</th>
          <th>16oz</th>
          <th>20oz</th>
        </tr>
      </thead>
      <tbody>
        {data.CoffeeBarTwelve.edges.map((d) => (
          <TableRowOne
            key={d.node.id}
            title={d.node.title}
            price01={formatter.format(d.node.price01)}
            price02={formatter.format(d.node.price02)}
            price03={formatter.format(d.node.price03)}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default CoffeeBarTwelve;
