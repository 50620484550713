import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Paragraph = styled.p`
  width: 80%;
  margin: 2rem auto 0 auto;
  line-height: 1.4;
  font-size: 2rem;

  ${MEDIA.MIN_TABLET`
    width: 60%;
  `};
`;
