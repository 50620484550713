import React from 'react';
import PropTypes from 'prop-types';

const Items = (props) => <>{props.price01}</>;

Items.propTypes = {
  props: PropTypes.any,
  id: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  category: PropTypes.string,
  description: PropTypes.string,
  price01: PropTypes.string,
  exception: PropTypes.string,
};

export default Items;
